import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { StatusBar, Style } from '@capacitor/status-bar';

@Injectable({
    providedIn: 'root'
})
export class StatusBarService {
    public async setStatusBarStyleDark(): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            await StatusBar.setStyle({ style: Style.Dark });
        }
    }

    public async setStatusBarStyleLight(): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            await StatusBar.setStyle({ style: Style.Light });
        }
    }
}
