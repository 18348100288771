import { AppPages } from '../../../app.pages';
import { AuthService } from '@services/auth/auth.service';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';

/*
 * NonAuthGuard is to used redirected user if already logged in
 */

export const nonAuthGuard: CanActivateFn = async (): Promise<boolean | UrlTree> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return !authService.token ? true : router.parseUrl(AppPages.ScansList);
};
