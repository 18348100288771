import * as Sentry from '@sentry/angular-ivy';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { bootstrapApplication } from '@angular/platform-browser';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

if (environment.production) {
    Sentry.init({
        dsn: 'https://4784908530d64b1bb66fabbaae74fd1c@sentry.io/1711718',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/app\.ifeet\.app/],
        // Performance Monitoring
        tracesSampleRate: 0.2, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    enableProdMode();
    window.console.log = (): void => {};
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
