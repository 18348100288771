import * as Sentry from '@sentry/angular-ivy';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import { AuthService, authFactory } from '@services/auth/auth.service';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import {
    PreloadAllModules,
    RouteReuseStrategy,
    Router,
    provideRouter,
    withComponentInputBinding,
    withPreloading
} from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { customHttpInterceptor } from '@shared/helpers/http-interceptor';
import { provideServiceWorker } from '@angular/service-worker';
import { routes } from './app.routes';
import { translationLoaderFactory } from '@shared/factories/translate.factory';

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideIonicAngular(),
        provideHttpClient(),
        provideHttpClient(withInterceptors([customHttpInterceptor])),
        provideRouter(routes, withComponentInputBinding(), withPreloading(PreloadAllModules)),
        {
            provide: APP_INITIALIZER,
            useFactory: authFactory,
            deps: [AuthService],
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        },
        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: translationLoaderFactory,
                    deps: [HttpClient]
                }
            })
        ),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
        })
    ]
};
