import { Injectable, inject } from '@angular/core';
import { LanguageService } from '@services/language/language.service';
import { Platform } from '@ionic/angular/standalone';
import { ServiceWorkerService } from '@services/service-worker/service-worker.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBarService } from '@services/status-bar/status-bar.service';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {
    private readonly languageService: LanguageService = inject(LanguageService);
    private readonly platform: Platform = inject(Platform);
    private readonly statusBarService: StatusBarService = inject(StatusBarService);
    private readonly serviceWorkerService: ServiceWorkerService = inject(ServiceWorkerService);

    constructor() {
        this.platform.ready().then(async () => {
            // only when on capacitor
            if (this.platform.is('capacitor')) {
                // hide splash screen before default timeout
                await SplashScreen.hide();
                // set status bar style
                await this.statusBarService.setStatusBarStyleDark();
            }
            // only when on web
            if (!this.platform.is('capacitor')) {
                // init service worker
                this.serviceWorkerService.initListener();
            }
            // init language service
            await this.languageService.initialize();
        });
    }
}
