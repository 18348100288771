import { AppPages } from '../../../app.pages';
import { AuthService } from '@services/auth/auth.service';
import { Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';

/*
 * AuthGuard is to used determine that user is logged in our not, if not we redirect to login page
 */

export const authGuard = async (): Promise<boolean | UrlTree> => {
    const router: Router = inject(Router);
    const authService: AuthService = inject(AuthService);
    return authService.token ? true : router.parseUrl(AppPages.Login);
};
