import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth/auth.guard';
import { nonAuthGuard } from '@guards/non-auth/non-auth.guard';

export const routes: Routes = [
    {
        path: 'login',
        loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
        canActivate: [nonAuthGuard]
    },
    {
        path: 'lostpw',
        loadComponent: () => import('./pages/lostpw/lostpw.page').then(m => m.LostPwPage),
        canActivate: [nonAuthGuard]
    },
    {
        path: 'pw-code',
        loadComponent: () => import('./pages/pw-code/pw-code.page').then(m => m.PwCodePage)
    },
    {
        path: 'setpw/:code',
        loadComponent: () => import('./pages/setpw/setpw.page').then(m => m.SetPwPage),
        canActivate: [nonAuthGuard]
    },
    {
        path: 'register',
        loadComponent: () => import('./pages/register/register.page').then(m => m.RegisterPage),
        canActivate: [nonAuthGuard]
    },
    {
        path: '404',
        loadComponent: () => import('./pages/not-found/not-found.page').then(m => m.NotFoundPage)
    },
    {
        path: '',
        loadChildren: () => import('./pages/tabs/tabs.routes').then(m => m.routes),
        canActivate: [authGuard]
    },
    { path: '**', redirectTo: '404' }
];
