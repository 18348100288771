import { DialogService } from '../dialog/dialog.service';
import { Injectable, inject, isDevMode } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ServiceWorkerService {
    private readonly dialogService: DialogService = inject(DialogService);
    private readonly swUpdate: SwUpdate = inject(SwUpdate);

    constructor() {
        this.initListener();
    }

    public initListener(): void {
        if (!isDevMode() && navigator.serviceWorker) {
            this.swUpdate.versionUpdates
                .pipe(
                    filter((evt): evt is VersionReadyEvent => {
                        return evt.type === 'VERSION_READY';
                    }),
                    map((evt: VersionReadyEvent) => {
                        return {
                            type: 'UPDATE_AVAILABLE',
                            current: evt.currentVersion,
                            available: evt.latestVersion
                        };
                    })
                )
                .subscribe(async () => {
                    await this.showModal();
                });
        }
    }

    private async showModal(): Promise<void> {
        await this.dialogService.showAlert({
            title: 'alert_sw_update_title',
            message: 'alert_sw_update_subtitle'
        });
        window.location.reload();
    }
}
