import { AppConstants } from 'src/app/app.constants';
import { Device } from '@capacitor/device';
import { Injectable, inject } from '@angular/core';
import { StorageService } from '@services/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private readonly defaultLanguage = 'de';
    private readonly storageService: StorageService = inject(StorageService);
    private readonly translate: TranslateService = inject(TranslateService);
    private readonly validLanguages = ['en', 'de'];

    /*
     * Will set the language to the device language if available, otherwise to the default language
     * In case user is logged in, the language will be set to the user's language
     */
    public async initialize(): Promise<void> {
        this.translate.setDefaultLang(this.defaultLanguage);
        this.translate.addLangs(this.validLanguages);
        // get language from device
        const { value } = await Device.getLanguageCode();
        // if available, use it, otherwise use default language
        let lang = value ? value : this.defaultLanguage;
        // retrieve user data from storage
        const userLanguage = await this.storageService.get(AppConstants.USER_LANGUAGE);
        // if user language is available, use it
        if (userLanguage) {
            lang = userLanguage;
        }
        const langToUse = this.validLanguages.includes(lang) ? lang : this.defaultLanguage;
        await firstValueFrom(this.translate.use(langToUse));
    }
}
