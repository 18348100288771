import { AppInitService } from './services/app-init/app-init.service';
import { Component, inject } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';

@Component({
    imports: [IonApp, IonRouterOutlet],
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true
})
export class AppComponent {
    private readonly appInitService: AppInitService = inject(AppInitService);
}
