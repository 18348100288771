import { AuthService } from '@services/auth/auth.service';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { LoadingController } from '@ionic/angular/standalone';
import { Observable, catchError, throwError } from 'rxjs';
import { ToastService } from '@services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { inject } from '@angular/core';

export const customHttpInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const authService: AuthService = inject(AuthService);
    const toastService: ToastService = inject(ToastService);
    const translate: TranslateService = inject(TranslateService);
    const loadingController: LoadingController = inject(LoadingController);

    return next(req).pipe(
        catchError((response: any) => {
            handleError(response, authService, toastService, translate, loadingController);
            displayToast(response, toastService, translate);
            dismissLoadingController(loadingController);
            return throwError(() => new Error(response.error));
        })
    );
};

const handleError = (
    response: any,
    authService: AuthService,
    toastService: ToastService,
    translate: TranslateService,
    loadingController: LoadingController
): void => {
    displayToast(response, toastService, translate);
    executeActionBasedOnError(response, authService);
    dismissLoadingController(loadingController).then(() => throwError(() => new Error(response.error)));
};

const displayToast = (response: any, toastService: ToastService, translate: TranslateService): void => {
    const message = translate.instant(response['error']['message'] || 'error_message_generic');
    toastService.showToast({ text: message });
};

const executeActionBasedOnError = async (response: any, authService: AuthService): Promise<void> => {
    switch (response['error']['code']) {
        case 102:
            await authService.logout(1500);
            break;
    }
};

const dismissLoadingController = async (loadingController: LoadingController): Promise<void> => {
    if (await loadingController.getTop()) {
        await loadingController.dismiss();
    }
};
